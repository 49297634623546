<template>
  <section class="sign-up h-100">
    <div class="flex h-screen">
      <div class="m-auto lg:ml-40">
        <div class="mb-5">
          <h3 class="text align-self-center mr-auto">
            Let’s Confirm it’s You
          </h3>

          <p class="text align-self-center mr-auto">
            Please verify the information below
          </p>
        </div>

        <form class="w-full max-w-sm mt-14">
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label
                class="block text-white mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
                First Name
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                v-model="firstName"
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                type="text"
              >
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label
                class="block text-white mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
                Last Name
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                v-model="lastName"
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                type="text"
              >
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label
                class="block text-white mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
                Email Address
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                v-model="email"
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                type="text"
              >
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label
                class="block text-white mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
                Phone Number
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                v-model="phone"
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                type="text"
              >
            </div>
          </div>

          <div class="md:flex md:items-center mb-6">
            <router-link
              :to="{name: 'instructions'}"
              class="btn btn-primary"
            >
              This is correct
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SignupConfirmation',
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: ""
    };
  },
  mounted() {
    this.firstName = this.$route.query.firstName;
    this.lastName = this.$route.query.lastName;
    this.email = this.$route.query.email;
    this.phone = this.$route.query.phone;
  }
}
</script>

<style scoped>
.sign-up {
  background-image: url('~@/assets/images/onboarding.jpg');
  background-size: cover;
  background-position: 50%;
  height: 100vh!important;
}
h3 {
  font-family: 'Helvetica';
  font-size: 34px;
  line-height: 50px;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-weight: 400;
}
h3 > strong {
  font-weight: bold;
}
.sign-up p {
  font-family: 'Helvetica';
  font-size: 22px;
  line-height: 32px;
  color: #FFFFFF;
  margin-bottom: 0;
  font-weight: 300;
}
.sign-up .social-links {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 45px;
  padding: 10px;
  min-height: 50px;
}
.sign-up .social-links img {
  height: 24px;
}

/* FIX: Input must be a component */
input {
  background: rgba(0, 0, 0, 0.5)!important;
  color: #FFFFFF!important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 30px;
  padding: 10px;
  min-width: 250px;
}
input::placeholder {
  color: #FFFFFF!important;
  opacity: 1!important;
}
input:-ml-input-placeholder {
  color: #FFFFFF!important;
}
input::-ml-input-placeholder {
  color: #FFFFFF!important;
}

a.btn-primary {
  background-color: #FF6600;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FF6600;
  box-sizing: border-box;
  border-radius: 35px;
}
</style>
